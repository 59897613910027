<!-- UserDetailPage.vue -->

<template>
  <div>
    <h1>מנעול  {{ lockName }}</h1>

    <div>
      
  <b-modal ref="alert" id="alert" ok-only title="" style="direction: rtl;">
    <p class="my-4" style="color: red;" v-if="this.errorText">{{ this.errorText }}</p>
    <p class="my-4" style="color: green;" v-if="this.succsessText">{{ this.succsessText }}</p>
  </b-modal>

    <div class="user-table">


    <div class="form-group" >
      <label for="filter">חפש:</label>
      <input type="text" class="form-control" id="filter" v-model="filterText" style="max-width: 100px;    display: unset;
">      <button @click="openAddUserModal" class="btn btn-success">הוספת משתמש חדש</button>

    </div>

    <b-spinner v-if="loading" label="Loading..."></b-spinner>


      <div v-for="user in filteredUsers" :key="user.id" class="table-row">
        <div class="table-cell">{{ user.username }}</div>
        <div class="table-cell">{{ user.keyName }}</div>
        
        <button @click="editUser(user)" class="btn btn-primary">ערוך</button>
        <button @click="confirmDelete(user)" class="btn btn-danger">מחק</button>


        <!-- Display other user details as needed -->
      </div>
    </div>

  </div>

 
 <!-- Edit User Modal -->
 <b-modal v-model="showEditUserModal" title="עריכת משתמש" >
      <div class="form-group">
        <label for="editUserName">מזהה</label>
        <b-form-input v-model="editUserData.username" id="editUserName" :disabled="!enableEditUserName" ></b-form-input>
      </div>
      <div class="form-group">
        <label for="editUserPhone">שם</label>
        <b-form-input v-model="editUserData.keyName" id="editUserPhone"></b-form-input>
      </div>

      <div class="form-group">
        <b-form-checkbox v-model="editUserData.remoteCheck">הפעלה מרחוק</b-form-checkbox>
      </div>

      <div class="form-group">
              <label for="avatar">תמונה:</label>
              <input type="file" class="form-control" id="avatar" @change="onFileChange" >
              <img class="pp" :src=pp_base+encodedUsername+pp_type >
            </div>
            <div class="form-group">
              <img v-if="editUserData.avatar" :src="editUserData.avatar" alt="Avatar Preview" style="max-width: 300px">
            </div>

      <template #modal-footer>
        <b-button variant="secondary" @click="closeEditUserModal">ביטול</b-button>
        <b-button variant="primary" @click="saveUserData"><b-spinner v-if="saving" small label="Loading..."></b-spinner> שמירה </b-button>
      </template>
    </b-modal>
    
  </div>
</template>
<style>
/* Add Flexbox styling to create a flexible user list */

.btn{
  margin: 10px;
}
.user-list {
  display: flex;
  flex-wrap: wrap;
}

.user-item {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 10px;
  flex: 1 0 calc(25% - 20px); /* Adjust the width to your preference */
}



.user-table {
  display: inline-block;
  flex-direction: column;
  border: 1px solid #ddd;
  margin: 10px;
  max-width: 700px;
}

.table-header {
  display: flex;
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 8px;
}

.table-cell {
  flex: 1;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
.table-row{
  direction: ltr;
  display: flex;
  padding: 20px;
}
.table-row:last-child .table-cell {
  border-bottom: none;
}

.form-group{
  direction: rtl;
}
</style>
<script>
import { myMixin } from '../mixins/mixin.js'
import axios from 'axios';

export default {
  props: ['lockName'],
  data() {
    return {
      pp_base : 'https://firebasestorage.googleapis.com/v0/b/ttlock-5f9b8.appspot.com/o/',
      pp_type : '.jpg?alt=media',
      users: [],
      selectedUserId: null,
      editUserData: {
        username: '',
        keyName: '',
        remoteEnable: 1,
        remoteCheck:true,
        avatar: null, // New property to store the selected image
      },
      showEditUserModal: false,
      filterText : '',
      enableEditUserName :  false,
      errorText : '',
      succsessText:'',
      saving: false,
      loading:true
    }
  },
  computed: {
    lockId() {
      return this.$route.params.lockId;
    },
    encodedUsername() {
      // Replace '+' with '%2B' in the editUserData.username
      return this.editUserData.username.replace(/\+/g, '%2B');
    },
    filteredUsers() {
      // Filter the users based on the filterText
      const filterText = this.filterText.toLowerCase().trim();
      console.log("🚀 ~ file: UsersForLockDetailPage.vue:135 ~ filteredUsers ~ filterText:", filterText)
      
      if (!filterText) {
        return this.users;
      } else {
        return this.users.filter(user =>
          user.username.toLowerCase().includes(filterText) ||
          user.keyName.includes(filterText) 
        );
      }
    },
  },
  async created() {
    await this.fetchUser();
  },
  methods: {
    async fetchUser() {
      this.loading = true;
      this.$forceUpdate();
      this.users =  await myMixin.methods.getekeyListForLock(this.lockId);
      console.log("🚀 ~ file: UsersForLockDetailPage.vue:28 ~ fetchUser ~ res:", this.users)
      this.loading = false;
      this.$forceUpdate();
    },
    confirmDelete(user) {
      if (window.confirm(`אתה  בטוח שאתה רוצה למחוק את  - ${user.keyName}?`)) {
        this.deleteUser(user.keyId);
      }
    },
    async deleteUser(username) {
      // Handle delete action here
      console.log(`Deleting user with ID: ${username}`);
      let res =  await myMixin.methods.deleteKey(username);
      console.log(res);
      if (res == 'deleted')
      {
          this.errorText  = '',
          this.succsessText = 'העדכון בוצע בהצלחה';
          this.$refs['alert'].show();
          await this.fetchUser();
      }
      else{
          this.errorText  = 'שגיאה';
          this.errorText = this.errorText + "  : "+res.message;
          this.succsessText = '';
          this.$refs['alert'].show()
      }

    },
    editUser(user) {
      console.log("🚀 ~ file: UsersForLockDetailPage.vue:153 ~ editUser ~ user:", user)
      if (user) {
        this.editUserData.username = user.username;
        this.editUserData.keyName = user.keyName;
        this.editUserData.remoteCheck = false;
        if (user.remoteEnable==1) this.editUserData.remoteCheck = true;
        this.selectedUserId = user.username;
        // Show the edit user modal
        this.showEditUserModal = true;
        this.enableEditUserName = false;
      }
    },
    closeEditUserModal() {
      // Close the edit user modal
      this.showEditUserModal = false;
    },
    openAddUserModal() {
      // Open the modal to add a new user with empty fields
      this.editUserData.username = '';
      this.editUserData.keyName = '';
      this.editUserData.remoteEnable = 2;
      this.editUserData.remoteCheck = false;
      //this.editUserData.filterText = ''; // Clear the filter text when opening the modal for a new user
      this.selectedUserId = 0;
      this.showEditUserModal = true;
      this.enableEditUserName = true;

    },
    saveEditUser() {
      // Find the user by ID and update their data
      const userIndex = this.users.findIndex(user => user.id === this.selectedUserId);
      if (userIndex !== -1) {
        this.users[userIndex].name = this.editUserData.name;
        this.users[userIndex].phone = this.editUserData.phone;
      }
      // Close the edit user modal
    },
    onFileChange(event) {
      console.log("🚀 ~ file: UsersForLockDetailPage.vue:208 ~ onFileChange ~ event:", event)
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          //this.editUserData.avatar = reader.result;
          this.editUserData.avatar = fileContent;
        };
        reader.readAsDataURL(file);
      } else {
        this.editUserData.avatar = null;
      }
    },
    async saveUserData() {
      this.saving = true;
      // Convert the image data to a FormData object
      const formData = new FormData();

      formData.append('userName', this.editUserData.username);
      console.log("🚀 ~ file: UsersForLockDetailPage.vue:230 ~ saveUserData ~ this.editUserData.username:", this.editUserData.username)
      formData.append('keyName', this.editUserData.keyName);
      this.editUserData.remoteEnable = 2;
      if(this.editUserData.remoteCheck == true) this.editUserData.remoteEnable = 1;
      formData.append('remoteEnable', this.editUserData.remoteEnable);
      formData.append('lockId', this.lockId);
      formData.append('accessToken', localStorage.getItem('accessToken'));
      
      // Check if an image was selected
      if (this.editUserData.avatar) {
        formData.append('file', this.editUserData.avatar);
      }

      try {
        //let domain = 'http://127.0.0.1:5001/ttlock-5f9b8/us-central1/updateUser'
        let domain= 'https://updateuser-ummnjtjqka-uc.a.run.app'; 
        // Make the API POST request using Axios
        const response = await axios.post(domain, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.saving = false;

        // Process the response as needed
        console.log( response.data);
        this.showEditUserModal = false;

        if(response.data.errcode == 0){
          this.errorText  = '',
          this.succsessText = 'העדכון בוצע בהצלחה';
          this.$refs['alert'].show();
          await this.fetchUser();
        }
        else{
          this.errorText  = 'שגיאה';
          this.errorText = this.errorText + "  : "+response.data.errmsg;
          this.succsessText = '';
          this.$refs['alert'].show()
        }

      } catch (error) {
        // Handle any errors that occurred during the upload process
        console.error('Error uploading user data:', error);
        this.errorText  = 'שגיאה',
        this.succsessText = '';
        this.$refs['alert'].show()
        this.saving = false;


      }
    }


  }
  }

</script>
