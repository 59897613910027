<template>
    <div class="container">
      <h1 class="mt-5">כניסה</h1>
      <form @submit.prevent="login" class="mt-4">
        <div class="form-group">
          <label for="username">משתמש:</label>
          <input type="text" id="username" v-model="userName" class="form-control" required>
        </div>
  
        <div class="form-group">
          <label for="password">סיסמה:</label>
          <input type="password" id="password" v-model="password" class="form-control" required>
        </div>
  
        <button type="submit" class="btn btn-primary"> <span v-if="loading" class="loaderLogin"></span> כניסה</button>
      </form>
      <br>
      <button v-if="isloggedin" class="btn" @click="logout">יציאה</button>

      <b-alert
      v-model="alertVisible"
      dismissible
      fade
      :variant="alertVariant"
      @dismissed="alertVisible = false"
    >
      {{ alertMessage }}
    </b-alert>
 
    </div>
  </template>
<script>    
import axios from 'axios';
import { myMixin } from '../mixins/mixin.js'
import { BAlert } from 'bootstrap-vue';


export default {
  components: {
    BAlert,
  },
  data() {
    return {
      //userName: myMixin.data().credentials.userName,
      //password: myMixin.data().credentials.password,
      userName: '',
      password: '',
      isloggedin : false,
      loading : false,
      alertVisible: false,
      alertVariant: '',
    };
  },
  created() {
    this.userName = localStorage.getItem('userName')
    
    this.isloggedin = localStorage.getItem('accessToken') != '' && localStorage.getItem('accessToken') != null && localStorage.getItem('accessToken') != undefined;
  },
  methods: {
    logout() {
      localStorage.setItem('accessToken', '');
      this.$router.push('/');
    },
    showAlert(variant, message) {
      this.alertVariant = variant;
      this.alertMessage = message;
      this.alertVisible = true;
    },
    async login() {
      // Replace 'your-web-server-endpoint' with the actual server endpoint to check user credentials
      // You can use Axios or fetch to make the HTTP request
      // Example using Axios:
      this.loading = true;
      this.alertVisible = false;

        let res =  await myMixin.methods.getAccessToken(this.userName,this.password);
        if (res == '' || res == null || res == undefined) {
          this.isloggedin = false;
          this.loading = false;
          this.showAlert('danger', `שם משתמש או סיסמה לא נכונים`);

        } else {
          this.isloggedin = true;
          this.loading = false;
          this.$router.push('/display');  
        }
    //   axios.post('your-web-server-endpoint', {
    //     username: this.username,
    //     password: this.password
    //   })
    //   .then(response => {
    //     if (response.data.authenticated) {
    //       // User authenticated, navigate to the display page
    //       this.$router.push('/display');
    //     } else {
    //       alert('Invalid credentials. Please try again.');
    //     }
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     alert('An error occurred. Please try again later.');
    //   });
    }
  }
};
</script>  
<style scoped>

.container {
    max-width: 400px!important;
}

.loaderLogin {
    width: 11px;
    height: 11px;
    border: 2px solid #f6f6f6;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
</style>