<template>
    <div class="container">
      <h3>רשימת מנעולים</h3>
      <span v-if="loading" class="loader"></span>

        <!-- v-for locks -->  
        <div v-if="finishLoading" style="display: flex; flex-wrap: wrap;    justify-content: space-evenly;">
        <h4 v-if="noLocks">לא נמצאו מנעולים עבור המשתמש </h4>
  <div v-for="(lock,index) in locks" :key="index">
    <div class="card" style="width: 18rem; margin: 10px;">🔒
      <div class="card-body" style="direction: ltr;">
        <!--
        <h5 class="card-title">keyName: {{lock.keyName}}</h5>
        <h6 class="card-subtitle mb-2 text-muted">keyId: {{lock.keyId}}</h6>    
        <h6 class="card-subtitle mb-2 text-muted">lockId: {{lock.lockId}}</h6>   
        -->
        <h6 class="card-subtitle mb-2 text-muted"> {{lock.lockName}}</h6>   
        <router-link :to="{ name: 'usersForLockDetailPage', params: { lockId: lock.lockId, lockName:lock.lockName  } }">ניהול משתמשים</router-link>

        <div v-if="lock.notify && lock.notify.visible">
          <p v-if="false">lockId: {{ lock.notify.lockId }}</p>
          <p>{{ lock.notify.displayUsername }}</p>
          <img class="pp" :src=pp_base+encodeString(lock.notify.username)+pp_type >
        </div> 
        <hr>                                             
      </div>            
    </div>
  </div>
</div>
      
      
    <div class="alert alert-danger" role="alert" v-if="networkError" >
      network error   
    </div>
    </div>
  </template>
<script>    
import axios from 'axios';
import { myMixin } from '../mixins/mixin.js'

import { db } from '../main.js'
import moment from 'moment'


export default {
  data() {
    return {
      pp_base : 'https://firebasestorage.googleapis.com/v0/b/ttlock-5f9b8.appspot.com/o/',
      pp_type : '.jpg?alt=media',
      networkError : false,
      locks : [],
      opens : [],
      finishLoading : false,
      loading : true,
      noLocks:false
    };
  },
  async created() {
    if (localStorage.getItem('accessToken') == '' || localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == undefined|| localStorage.getItem('accessToken') == 'undefined') {
      this.$router.push('/');
      return;
    }
    else {

      this.locks = await this.getLocks();

      if (this.locks == undefined || this.locks.length == 0  ) {
        this.finishLoading = true;
        this.loading = false;
        this.noLocks = true;
        return;
      }
    this.locks = this.locks.splice(0, 30);

    this.finishLoading = true;

    console.log(this.locks);

    let lockids = []; // Array of document IDs

    // Reference the Firebase real-time database collection
    // const db = firebase.firestore();
    for (let index = 0; index < this.locks.length; index++) {
      const lock = this.locks[index];
      lockids.push(lock.lockId.toString());
    };
    lockids = lockids.splice(0, 30);
    console.log("🚀 ~ file: DisplayPage.vue:57 ~ created ~ lockids:", lockids)
    
    const query = db.collection('lastLockEntry').where('lockId', "in", lockids);

    const observer = query.onSnapshot(
      { includeMetadataChanges: false },
      querySnapshot => {
      console.log(`Received query snapshot of size ${querySnapshot.size}`);

      querySnapshot.docChanges().forEach(change => {
        
        console.log('doc: ', change.doc.data());
        this.pushCall(change.doc.data());
    });


      //const updatedData = querySnapshot.data();
      //console.log('Updated data:', updatedData);
      // ...
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

  this.loading = false;

  
    }
    
  },
  methods: {
    encodeString(input) {
      // Replace '+' with '%2B' and other special characters as needed
      return encodeURIComponent(input).replace(/\+/g, '%2B');
    },
    async getLocks() {
        this.noLocks = false;
        let res =  await myMixin.methods.getKeyList();
        return res;
    
    },
    pushCall(newData) {
      let id = newData.lockId;
      // Find the item by ID inside the array
    const foundItemIndex = this.locks.findIndex(item => item.lockId.toString() == id.toString());

    if (foundItemIndex !== -1) {

      // ignor old data
      const currentTime = moment().unix(); // Get the current time
      console.log("🚀 ~ file: DisplayPage.vue:105 ~ pushCall ~ currentTime:", currentTime)
      const timestampTime = moment(newData.lockDate/1000); // Convert the Unix timestamp to a Moment object
      console.log("🚀 ~ file: DisplayPage.vue:106 ~ pushCall ~ newData.lockDate:",timestampTime)
      const differenceInSeconds = currentTime - timestampTime;
      console.log("🚀 ~ file: DisplayPage.vue:109 ~ pushCall ~ differenceInSeconds:", differenceInSeconds)

      if (differenceInSeconds > 60) {
        console.log('The timestamp is more than 20 seconds ago');

      } else {
        console.log('The timestamp is within the last 5 seconds');

        
        var lockDate = moment(newData.lockDate)
        newData.lockDate = lockDate;
        newData.visible = true;
        newData.messageDate = moment();
        this.locks[foundItemIndex].notify = newData;

        let username = this.locks[foundItemIndex].notify.username;
        const asterisks = "*".repeat(username.length - 4);
        const lastFiveDigits = username.substr(-4);
        this.locks[foundItemIndex].notify.displayUsername = asterisks + lastFiveDigits;

        console.log("🚀 ~ file: DisplayPage.vue:104 ~ pushCall ~ this.locks[foundItemIndex]:", this.locks[foundItemIndex])
        this.$forceUpdate();

        
      }
    }
  },

    updateVisibility() {
      if(this.locks == undefined) return;
      const currentTime = new Date();

      this.locks.forEach(item => {
        if(item?.notify == undefined) return;
        const itemTime = new Date(item?.notify?.messageDate);
        const difference = currentTime - itemTime;
        const thirtySeconds = 30 * 1000; // 30 seconds in milliseconds
        const lastState = item.notify.visible;
        item.notify.visible = difference <= thirtySeconds;
        if(lastState != item.notify.visible) 
          this.$forceUpdate();
      });
    }
  },
  mounted() {
    setInterval(() => {
      this.updateVisibility();
    }, 3000); // Update every second (adjust as needed)
  },
};
</script>  

<style scoped>

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #2c7095;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    .pp {
  width: 100%;
  height: auto;
}
</style>