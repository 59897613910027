<template>
  <div id="app">
    <nav>
      <router-link to="/">כניסה</router-link> |
      <router-link to="/display">מנעולים</router-link>
    </nav>
    <router-view/>
  </div>
</template>
<script>    

export default {
  data() {
    return {
      //accessToken:''
    };
  },
  async created() {
   
    //this.accessToken = localStorage.getItem('accessToken')

  },
  methods: {
   
    
  },
  mounted() {
   
  },
};
</script>  

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  direction: rtl;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
