import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// Import the functions you need from the SDKs you need

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCM7jCMWheBEXxkBJJvOQrSU-FR6IAoZZE",
  authDomain: "ttlock-5f9b8.firebaseapp.com",
  projectId: "ttlock-5f9b8",
  storageBucket: "ttlock-5f9b8.appspot.com",
  messagingSenderId: "867129882087",
  appId: "1:867129882087:web:3c6cdb633e2169b9b7f942"
};

firebase.initializeApp(firebaseConfig);


let app = new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//export const db = app.firestore();

export var db = firebase.firestore();
