import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DisplayPage from '../views/DisplayPage.vue'
import UsersForLockDetailPage from '../views/UsersForLockDetailPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/display',
    name: 'display',
    component: DisplayPage
  },
  { path: '/users/:lockId', 
    name: 'usersForLockDetailPage',
    component: UsersForLockDetailPage,
    props: true

  }

]

const router = new VueRouter({
  routes
})

export default router
