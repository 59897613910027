import crypto from 'crypto-js';
import axios from 'axios';
import moment from 'moment';

export const myMixin = {
  data() {
    return {
      credentials: {
        clientId: 'efc96ba1563044718010e3083f3963af',
        clientSecret: '4236bcfb733e16f1955623269460d365',

        clientId_: '79d7c62ae78d4883959081bf2216154d',
        clientSecret_: 'c838b79daf3c03b177abcec27f15a7a4',

        
        userName: '+972522254461',
        password: 'Ra039415502',
        accessToken: ''
      },
    };
  },
  methods: {
    logMessage() {
      console.log(this.message);
    },


    async getAccessToken(userName,password) {
      let credentials = myMixin.data().credentials
      const url = 'https://getaccesstoken-ummnjtjqka-uc.a.run.app?userName='+encodeURIComponent(userName)+'&password='+password;
      console.log("🚀 ~ file: mixin.js:31 ~ getAccessToken ~ url:", url)
      const data = {
        clientId: credentials.clientId,
        clientSecret: credentials.clientSecret,
        username: encodeURIComponent(userName),
        //password: crypto.createHash('md5').update(Password).digest('hex'),
        password: crypto.MD5(password).toString(),
      };
      const headers = {
        //'Content-Type': 'application/x-www-form-urlencoded'
      };

      try {
        const response = await axios.get(url, { headers });

        console.log("getAccessToken response");
        console.log(response);
        localStorage.setItem('accessToken', response.data.result)

        console.log("🚀 ~ file: mixin.js:48 ~ getAccessToken ~ response.data.result:", response.data.result)

       // myMixin.data().credentials.accessToken = response.data.result;
        //console.log("🚀 ~ file: mixin.js:48 ~ getAccessToken ~ myMixin.data().credentials.accessToken:", myMixin.data().credentials.accessToken)
        return response.data.result;
      } catch (error) {
        console.error(error);
        //myMixin.data().credentials.accessToken = '';
        localStorage.setItem('accessToken', '')
        localStorage.setItem('userName', userName)

        return '';
      }
    },

    async getKeyList() {
      
      const url = `https://getkeylist-ummnjtjqka-uc.a.run.app?accessToken=${ localStorage.getItem('accessToken')}`;
      //const url = `http://127.0.0.1:5001/ttlock-5f9b8/us-central1/getKeyList?accessToken=${ localStorage.getItem('accessToken')}`;
      
      console.log("🚀 ~ file: mixin.js:59 ~ getKeyList ~ url:", url)
      try {

        const response = await axios.get(url);

        const keyList = response.data.result;        ;
        console.log("🚀 ~ file: mixin.js:64 ~ getKeyList ~ keyList:", keyList)
        return keyList;
      } catch (error) {
        console.error(error);
      }
    },

    async deleteKey(keyId) {
      
      const url = `https://deletekey-ummnjtjqka-uc.a.run.app`;
      //const url = `http://127.0.0.1:5001/ttlock-5f9b8/us-central1/deleteKey`;
      const data = {
        accessToken :localStorage.getItem('accessToken'),
        keyId : keyId
      }
      try {
        console.log("🚀 ~ file: mixin.js:94 ~ deleteKey ~ data:", data)

        const response = await axios.post(url,data);

        const keyList = response.data.result;        ;
        console.log("🚀 ~ file: mixin.js:64 ~ getKeyList ~ keyList:", keyList)
        return keyList;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async getekeyListForLock(lockId) {
      const date = Date.now();
      const pageNo = 1;
      const pageSize = 100000;
      //const url = `http://127.0.0.1:5001/ttlock-5f9b8/us-central1/getekeyListForLock?accessToken=${ localStorage.getItem('accessToken')}&lockId=`+lockId;
      const url = `https://getekeylistforlock-ummnjtjqka-uc.a.run.app?accessToken=${ localStorage.getItem('accessToken')}&lockId=`+lockId;

      try {

        const response = await axios.get(url);

        const keyList = response.data;
        if(keyList.result && keyList.result.length > 0 ){
          return keyList.result
        } 
        return null;
      } catch (error) {
        console.error(error);
      }
    },

    convertToUnixTime(dateString, formatString) {
      // convert date string to Unix timestamp
      return moment(dateString, formatString).unix() * 1000;

    }






  }
};
